<template>
  <div class="miniprogram">
    <img src="../../assets/product/miniprogram/1.png" class="headerimg" />
    <div class="contertop">
      <div class="title">微信小程序能给企业带来什么？</div>
      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/2.png" class="cardimg" />
          <div class="cardtext">高效传播</div>
          <div class="cardinfo">
            <div>小程序+公众号+微信群</div>
            <div class="text">拓宽企业传播渠道，提升曝光率</div>
          </div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/3.png" class="cardimg" />
          <div class="cardtext">轻松获客</div>
          <div class="cardinfo">
            <div>线上线下无缝衔接</div>
            <div class="text">社交流量助力轻松获客</div>
          </div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/4.png" class="cardimg" />
          <div class="cardtext">效益倍增</div>
          <div class="cardinfo">
            <div>把握更多商机</div>
            <div class="text">低成本开发、高效益回报</div>
          </div>
        </div>
      </div>
    </div>

    <div class="contertop window">
      <div class="title">紧抓微信60+个流量窗口</div>
      <div class="title2">用户选择小程序</div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/5.png" class="cardimg" />
          <div class="cardtext">一扫直达页面</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/6.png" class="cardimg" />
          <div class="cardtext">搜索小程序</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/7.png" class="cardimg" />
          <div class="cardtext">附近的小程序</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/8.png" class="cardimg" />
          <div class="cardtext">公众号跳转小程序</div>
        </div>
      </div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/9.png" class="cardimg" />
          <div class="cardtext">分享小程序</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/10.png" class="cardimg" />
          <div class="cardtext">小程序互相跳转</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/11.png" class="cardimg" />
          <div class="cardtext">朋友圈广告</div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/12.png" class="cardimg" />
          <div class="cardtext">最近使用列表</div>
        </div>
      </div>
    </div>

    <div class="contertop">
      <div class="title">直观展示企业实力，提升客户信心</div>
      <div class="title2">企业展示</div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img
            src="../../assets/product/miniprogram/13.png"
            class="BuildStationcardimg"
          />
        </div>

        <div class="BuildStationcard">
          <img
            src="../../assets/product/miniprogram/14.png"
            class="BuildStationcardimg"
          />
        </div>

        <div class="BuildStationcard">
          <img
            src="../../assets/product/miniprogram/15.png"
            class="BuildStationcardimg"
          />
        </div>
      </div>
    </div>

    <div class="contertop">
      <div class="title">邦伲德轻站，3分钟拥有专属小程序</div>

      <div class="advantagelist">
        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/miniprogram/16.png" alt="" />
          </div>
          <div class="cardtitle">自由搭建</div>
          <div class="cardinfo">免代码拖拽式、自由搭建</div>
          <div class="cardinfo">专属个性化小程序</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/miniprogram/17.png" alt="" />
          </div>
          <div class="cardtitle">一键分发</div>
          <div class="cardinfo">微信、百度智能小程序</div>
          <div class="cardinfo">一键分发，同步上线</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/miniprogram/18.png" alt="" />
          </div>
          <div class="cardtitle">场景丰富</div>
          <div class="cardinfo">多行业模版一健套用</div>
          <div class="cardinfo">功能强大、持续更新</div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/miniprogram/19.png" alt="" />
          </div>
          <div class="cardtitle">服务保障</div>
          <div class="cardinfo">7×12小时售后服务</div>
          <div class="cardinfo">7×12小时安全监控</div>
        </div>
      </div>
    </div>

    <div class="information" style="background: #fff">
      <div class="title">适用行业</div>
      <div class="informationimg">
        <img src="../../assets/product/miniprogram/20.png" alt="" />
      </div>
    </div>

    <div class="contertop case conterbottom">
      <div class="title">众多商家的信赖之选</div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/21.png" class="cardimg" />
        </div>
        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/22.png" class="cardimg" />
        </div>
        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/23.png" class="cardimg" />
        </div>
        <div class="BuildStationcard">
          <img src="../../assets/product/miniprogram/24.png" class="cardimg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.case {
  .BuildStationlist {
    width: 80%;
    .BuildStationcard {
      .cardimg {
        width: 305px;
      }
    }
  }
}

.informationimg {
  img {
    width: 80% !important;
  }
}
.window {
  .BuildStationlist {
    .BuildStationcard {
      width: 204px;
      .cardimg {
        width: 80px;
      }
    }
  }
}

.advantagelist {
  justify-content: space-between !important;
  width: 80%;
  margin: auto;
  margin-top: 60px !important;
  .advantagecard {
    width: 228px;
    height: 368px;
    border-radius: 12px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-left: 0px !important;
    .cardimg {
      img {
        width: 228px;
        border-radius: 12px 12px 0px 0px;
      }
    }
    .cardtitle {
      // margin-top: 20px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
    }
    .cardinfo {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #616b80;
      line-height: 20px;
    }
  }
}

.BuildStationlist {
  margin: auto;
  margin-top: 60px;
  width: 55%;
  display: flex;
  justify-content: space-between;
  .BuildStationcard {
    text-align: center;
    .cardimg {
      width: 120px;
    }
    .BuildStationcardimg {
      width: 315px;
    }
    .cardtext {
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
    .cardinfo {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }
  }
}
</style>